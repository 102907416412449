import { github, medium, twitterX } from "../../icons/social";

import type { NavigationLink } from "../../header/types/NavigationLink";

export const socialLinks: NavigationLink[] = [
  // {
  //   name: "Discord",
  //   href: "https://discord.gg/wHW8tPyu",
  //   icon: discord,
  //   isExternal: true,
  // },
  {
    name: "X",
    href: "https://x.com/TaoTradeX",
    icon: twitterX,
    isExternal: true,
  },
  {
    name: "Medium",
    href: "https://taotrade.medium.com/",
    icon: medium,
    isExternal: true,
  },
  {
    name: "Github",
    href: "https://github.com/taotradetech",
    icon: github,
    isExternal: true,
  },
];
