import { ChainId } from "../../web3/types/ChainId";
import { VaultStatus } from "../types/VaultStatus";

import type { VaultConfig } from "../types/VaultConfig";

export const vaultConfigs: VaultConfig[] = [
  {
    id: "WTAO-USDC",
    chainId: ChainId.ARBITRUM,
    pairId: "WTAO-USDC",

    addresses: {
      vault: "0x55F306c12fFf5e2f0785CfAE2Cb2aa92710454F7",
    },

    status: VaultStatus.ACTIVE,
  },
  {
    id: "ETH-USDC",
    chainId: ChainId.ARBITRUM,
    pairId: "ETH-USDC",

    addresses: {
      vault: "0xFC895080aB211bB8B4CA4B0ac337C0568BC2F3fa",
    },

    status: VaultStatus.ACTIVE,
  },
  {
    id: "WBTC-USDC",
    chainId: ChainId.ARBITRUM,
    pairId: "WBTC-USDC",

    addresses: {
      vault: "0xC32d838AcEd54D48dD00bb382B0302C253A0CC04",
    },

    status: VaultStatus.ACTIVE,
  },
  {
    id: "ARB-USDC",
    chainId: ChainId.ARBITRUM,
    pairId: "ARB-USDC",

    addresses: {
      vault: "0xB188137e744e4F220Dc032EbbCE2F684850796A2",
    },

    status: VaultStatus.ACTIVE,
  },
];
