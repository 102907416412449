import { ChainId } from "../types/ChainId";

import type { ChainMetadata } from "../types/ChainMetadata";

export const chainsMetadata: ChainMetadata[] = [
  {
    chainId: ChainId.ARBITRUM,

    addresses: {
      priceOracle: "0x5caa572194872c62e5bceA41725ee2763072b937",
      referralManager: "0xB7dA4D2c210e641767d0A16D50B21EA936BCcE97",
      strikePriceManager: "0xe2D27F3A512f25c60978C203B3Aafb3a1d04A407",

      // TODO: update later
      vaultMigrationManager: "0xf350e47D1db625DA9cfa3A362A13839A550B15Ab",
    },
  },
];
