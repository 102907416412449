import { ChainId } from "../../web3/types/ChainId";

import type { PairConfig } from "../types/PairConfig";

export const pairConfigs: PairConfig[] = [
  {
    id: "WTAO-USDC",
    chainId: ChainId.ARBITRUM,
    chartSymbol: "KUCOIN:TAOUSDT",

    addresses: {
      baseToken: "0xa14a26bb46e236da394da6B09a5b4CF737ce707b",
      quoteToken: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
      vault: "0x55F306c12fFf5e2f0785CfAE2Cb2aa92710454F7",
      positionManager: "0x5a8683a15CCBF8a8aD51b5086896cefb9e8d83c3",
    },
  },
  {
    id: "ETH-USDC",
    chainId: ChainId.ARBITRUM,
    chartSymbol: "ETHUSDT",

    addresses: {
      baseToken: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      quoteToken: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
      vault: "0xFC895080aB211bB8B4CA4B0ac337C0568BC2F3fa",
      positionManager: "0x6df727f06ea808e14488535BF892CE8928FE3737",
    },
  },
  {
    id: "WBTC-USDC",
    chainId: ChainId.ARBITRUM,
    chartSymbol: "BTCUSDT",

    addresses: {
      baseToken: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      quoteToken: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
      vault: "0xC32d838AcEd54D48dD00bb382B0302C253A0CC04",
      positionManager: "0x98f0874Bb43A3A02ad41814ee649eA6B0eDDF286",
    },
  },
  {
    id: "ARB-USDC",
    chainId: ChainId.ARBITRUM,
    chartSymbol: "ARBUSDT",

    addresses: {
      baseToken: "0x912CE59144191C1204E64559FE8253a0e49E6548",
      quoteToken: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
      vault: "0xB188137e744e4F220Dc032EbbCE2F684850796A2",
      positionManager: "0xAf0ea8f0e713c5399747562a401418a7f095eb43",
    },
  },
];
