import {
  getFormattedProfitAndLoss,
  getFormattedProfitAndLossPercentage,
} from "../../shared/helpers/baseFormatters";
import {
  Container,
  InfoRow,
  InfoTitle,
  InfoColorValue,
} from "../../shared/modal/styles/ModalInfo";
import { useClosePositionModalState } from "../stores/useClosePositionModalState";

const profitAndLossFeePercentage = -0.05;

export const ProfitAndLossInfo = () => {
  const { profitAndLoss, profitAndLossPercentage } =
    useClosePositionModalState();

  const isPositive = profitAndLoss > 0;

  const formattedProfitAndLoss = getFormattedProfitAndLoss(profitAndLoss);
  const formattedProfitAndLossFee = getFormattedProfitAndLoss(
    profitAndLoss * profitAndLossFeePercentage
  );

  const formattedProfitAndLossPercentage = getFormattedProfitAndLossPercentage(
    profitAndLossPercentage
  );
  const formattedProfitAndLossFeePercentage =
    getFormattedProfitAndLossPercentage(profitAndLossFeePercentage);

  const formattedValue = `${formattedProfitAndLoss} (${formattedProfitAndLossPercentage})`;
  const formattedFeeValue = `${formattedProfitAndLossFee} (${formattedProfitAndLossFeePercentage})`;

  return (
    <Container>
      <InfoRow>
        <InfoTitle>PNL</InfoTitle>
        <InfoColorValue isPositive={isPositive}>
          {formattedValue}
        </InfoColorValue>
      </InfoRow>
      {isPositive ? (
        <InfoRow>
          <InfoTitle>PNL Fee</InfoTitle>
          <InfoColorValue isPositive={false}>
            {formattedFeeValue}
          </InfoColorValue>
        </InfoRow>
      ) : null}
    </Container>
  );
};
